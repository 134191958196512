const axios = require('axios');

module.exports = {
    getIpAddressInfo: async () => {
        const response = await axios.get(`https://www.cloudflare.com/cdn-cgi/trace`)
        if (!response.status === 200) return;
        const arr = response.data.split(/\r?\n/);
        const data = {};
        for (let row of arr) {
            const key = row.split('=')[0];
            const value = row.split('=')[1];
            data[key] = value;
        }
        return data;
    }
}
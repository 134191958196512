import firebase from "gatsby-plugin-firebase"
import { getIpAddressInfo } from "../modules/ipAddressInfo";

export const signup = async (
    userId,
    email
) => {
    try {
        // Only set optinIp/date if not set
        const userData = {
            email: email
        };
        const userRef = firebase.firestore().collection("users").doc(userId);

        const doc = await userRef.get();
        if (!doc.exists ||
            (!doc.get('optinIp'))
            ||
            (!doc.get('signupIp'))
        ) {
            const ipAddressInfo = await getIpAddressInfo();

            if (!doc.get('optinIp')) {
                userData.optinIp = ipAddressInfo.ip;
                userData.optinDate = firebase.firestore.FieldValue.serverTimestamp();
            }

            if (!doc.get('signupIp')) {
                userData.signupIp = ipAddressInfo.ip;
                userData.signupDate = firebase.firestore.FieldValue.serverTimestamp();
            }
        }

        await userRef.set(userData, {
            merge: true
        });

        try {
            await userRef.collection('newsletters').doc('8f7ce1e66a').set({
                enabled: true
            });
        } catch (err) {
            // user doesnt (currently) have the permission to update newsletters/{listId}
            // so we catch any possible permission errors for a second signup
        }

        return true;
    } catch (err) {
        return false;
    }

}

export const validate = (values, fields) => {
    const errors = {};
    for (let field of fields) {
        if (field.required === true && !values[field.internalName]) {
            errors[field.internalName] = 'Dit veld is verplicht';
        } else if (field.validateRegex
            && !(new RegExp(field.validateRegex).test(values.email))) {
            errors[field.internalName] = field.errorText;
        }
    }
    return errors;
}


import { graphql } from "gatsby"
import React, { useState, useEffect } from "react"
import {
  newsletterMain,
  newsLetter,
  newsletterForm,
  formFieldWrapperOptin,
  formFieldError,
  mailchimpResponse,
} from "./nieuwsbrief.module.css"
import { Formik, Form, Field, ErrorMessage } from "formik"
import firebase from "gatsby-plugin-firebase"
import { getUser } from "../services/users"
import { signup } from "../services/newsletter"
import { Helmet } from "react-helmet"

/* Components */
import Layout from "../components/layout"
import Seo from "../components/seo/seo"
import Header from "../components/header/header"
import Footer from "../components/footer/footer"
import { dataLayer } from "../modules/analytics"

const NewsletterPage = ({ data }) => {
  const [mcResponse, setMcResponse] = useState(null)
  const [user, setUser] = useState({})
  const desks = data.allContentfulDesk

  const fieldsInitialValues = {
    optin: false,
    email: "",
  }

  const [initialValues, setInitialValues] = useState(fieldsInitialValues)

  useEffect(() => {
    if (!firebase) return
    firebase.auth().onAuthStateChanged(async (user) => {
      if (!user) return await firebase.auth().signInAnonymously()
      const userData = await getUser(user.uid)
      if (userData) setInitialValues(userData)
      setUser(user)
    })
  }, [])

  const handleSubmit = async (formData) => {
    if (formData.email) {
      let response = await signup(user.uid, formData.email)
      setMcResponse(response)
      dataLayer({
        event: "website-interaction",
        category: "call-to-action",
        action: `newsletter-signup:${response.result}`,
        label: `position:landingpage`,
      })
    }
  }
  return (
    <Layout>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.mkbservicedesk.nl/nieuwsbrief/"
        />
      </Helmet>
      <Header desks={desks} />
      <main className={newsletterMain}>
        <div className="container">
          <Seo title="Nieuwsbrief" />
          <div className={newsLetter}>
            <h1>Elke week tips en inspiratie in je inbox!</h1>
            <div className={newsletterForm}>
              <div className={"newsLetter"}>
                <p>Schrijf je in voor onze nieuwsbrief en ontvang elke week een
                  mail met praktische informatie die je bedrijf écht vooruit
                  helpt.
                </p>

                <Formik
                  enableReinitialize={true}
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                >
                  <Form>
                    <div className={"formFieldWrapper"} key="email">
                      <Field
                        validate={(value) =>
                          value ? undefined : "E-mailadres is verplicht"
                        }
                        className={"formField"}
                        placeholder="Voer je e-mailadres in"
                        type="email"
                        name="email"
                      />
                    </div>

                    {!initialValues.optin && (
                      <div className={formFieldWrapperOptin} key="optin">
                        <label
                          className={`formFieldLabelOptin download-form-optin`}
                          htmlFor="optin"
                        >
                          Bij het inschrijven ga ik akkoord met de{" "}
                          <a
                            href="/algemene-voorwaarden/"
                            target="_blank"
                          >
                            Algemene voorwaarden
                          </a>{" "}
                          en de{" "}
                          <a href="/privacy-verklaring/" target="_blank">
                            Privacy Statement
                          </a>{" "}
                          en geef ik toestemming voor het ontvangen van
                          nieuwsbrieven.
                        </label>
                        <Field
                          validate={(value) =>
                            value
                              ? undefined
                              : "Opt-in is nodig om verder te gaan"
                          }
                          className={"formCheckbox"}
                          type="checkbox"
                          name="optin"
                        />
                        <ErrorMessage
                          className={`${formFieldError} download-form-field-error`}
                          name="optin"
                          component="div"
                        />
                      </div>
                    )}

                    <button type="submit">Verzend</button>
                  </Form>
                </Formik>

                {mcResponse === true && (
                  <p className={mailchimpResponse}>
                    Bedankt! Je bent aangemeld voor de nieuwsbrief
                  </p>
                )}

                {mcResponse === false && (
                  <p className={mailchimpResponse}>
                    Aanmelden mislukt. Probeer het opnieuw of gebruik een ander
                    e-mailadres
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </Layout>
  )
}

export default NewsletterPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    allContentfulDesk(sort: { fields: name }) {
      nodes {
        name
        slug
        legacyId
        sortNavigation
      }
    }
  }
`

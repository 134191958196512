import firebase from "gatsby-plugin-firebase"

export const getUser = async (uid, field) => {
    try {
        const userSnap = await firebase.firestore().collection("users").doc(uid).get();
        if (field) return userSnap.get(field);
        return userSnap.data();
    } catch (err) {
        //console.log(err);
    }
}

export const validate = (values, fields) => {
    const errors = {};
    for (let field of fields) {
        if (field.required === true && !values[field.internalName]) {
            errors[field.internalName] = 'Dit veld is verplicht';
        } else if (field.validateRegex
            && !(new RegExp(field.validateRegex).test(values.email))) {
            errors[field.internalName] = field.errorText;
        }
    }
    return errors;
}

